import Vue from 'vue'

class Helper
{
	faltantes(aBuscar, origen) {
		let faltantes = [];

		for(let i=0; i<aBuscar.length; i++) {
			let encontrado = false;
			for(let a=0; a<origen.length; a++) {
				if (aBuscar[i].account_id == origen[a].account_id) {
					encontrado = true;
					a=origen.length;
				}
			}

			if (!encontrado)
				faltantes.push(aBuscar[i]);
		}

		return faltantes;
	}
}

export default new Helper();