<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Autorizadores</h1>
		</div>
		<br />
		<DataGrid :config="dataGridConfig" :data="autorizadores" :select="seleccionar" @actions="data_grid_actions">
			<div class="col-md-2">
				<button class="btn warning-btn" @click="obtener_autorizadores">Recargar</button>
			</div>
			<div class="col-md-2">
				<button v-if="$auth.can('tesoreria', 'crear_autorizadores')" class="btn complementary-btn" @click="form_autorizador_tipo(1)">Agregar</button>
			</div>
			<div class="col-md-2">
				<button v-if="$auth.can('tesoreria', 'editar_autorizadores')" class="btn secondary-btn" @click="form_autorizador_tipo(2)">Editar</button>
			</div>
			<div class="col-md-2">
				<button v-if="$auth.can('tesoreria', 'eliminar_autorizadores')" class="btn danger-btn" @click="eliminar_autorizadores">Eliminar</button>
			</div>
		</DataGrid>

		<Modal v-if="form_autorizador" :options="{width:'70vw'}" class="bpb-modal" @close="cancelar_autorizador">
			<h2 class="title">{{ form_autorizador == 1 ? 'Nuevo' : 'Editar' }} autorizador</h2>
			<div class="body">
				<div v-if="form_autorizador == 1" class="row form-group">
					<label for="usuario" class="col-form-label col-sm-3 col-xl-2">Usuario</label>
					<div class="col-sm-9 col-xl-10">
						<select v-model="autorizador.account_id" name="usuario" id="usuario" class="form-control">
							<option v-for="usuario in usuarios" :value="usuario.id">{{ usuario.nombre }}</option>
						</select>
					</div>
				</div>
				<div v-else class="row form-group">
					<label for="usuario" class="col-form-label col-sm-3 col-xl-2">Usuario</label>
					<div class="col-sm-9 col-xl-10">{{ autorizador.nombre }}</div>
				</div>
				<div class="row form-group">
					<label for="area" class="col-form-label col-sm-3 col-xl-2">Área</label>
					<div class="col-sm-9 col-xl-10">
						<select v-model="autorizador.area" name="area" id="area" class="form-control">
							<option v-for="area in areas" :value="area">{{ area }}</option>
						</select>
					</div>
				</div>
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-3 col-xl-2">Pre-autorizadores</label>
					<div class="col-sm-9 col-xl-10">
						<div class="row mb-2">
							<div class="col-sm-12 text-right">
								<button class="btn btn-primary" @click="form_usuarios(1)">Agregar pre-autorizador</button>
							</div>
						</div>
						<div v-if="autorizador.preautorizadores.length > 0" class="row">
							<div class="col-sm-6">
								<div class="container-fluid">
									<div class="row list-title">
										<div class="col-sm-10">Pre-autorizador</div>
										<div class="col-sm-2 text-center">#</div>
									</div>
									<div v-for="tmp_preautorizador in preautorizador_bloque_a" :key="tmp_preautorizador.id" class="row list-content">
										<div class="col-sm-10">{{ tmp_preautorizador.nombre }}</div>
										<div class="col-sm-2 text-center">
											<img style="width: 15px" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Quitar proveedor" title="Quitar proveedor" @click="quitar_usuario(1, tmp_preautorizador.id)">
										</div>
									</div>
								</div>
							</div>
							<div v-if="autorizador.preautorizadores.length > 1" class="col-sm-6">
								<div class="container-fluid">
									<div class="row list-title">
										<div class="col-sm-10">Pre-autorizador</div>
										<div class="col-sm-2 text-center">#</div>
									</div>
									<div v-for="tmp_preautorizador in preautorizador_bloque_b" :key="tmp_preautorizador.id" class="row list-content">
										<div class="col-sm-10">{{ tmp_preautorizador.nombre }}</div>
										<div class="col-sm-2 text-center">
											<img style="width: 15px" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Quitar proveedor" title="Quitar proveedor" @click="quitar_usuario(1, tmp_preautorizador.id)">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else class="row">
							<div class="col-sm-12 text-center">No hay pre-autorizadores asignados</div>
						</div>
					</div>
				</div>
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-3 col-xl-2">Proveedores</label>
					<div class="col-sm-9 col-xl-10">
						<div class="row mb-2">
							<div class="col-sm-12 text-right">
								<button class="btn btn-primary" @click="form_usuarios(2)">Agregar proveedor</button>
							</div>
						</div>
						<div v-if="autorizador.proveedores.length > 0" class="row">
							<div class="col-sm-6">
								<div class="container-fluid">
									<div class="row list-title">
										<div class="col-sm-10">Proveedor</div>
										<div class="col-sm-2 text-center">#</div>
									</div>
									<div v-for="proveedor in bloque_a" :key="proveedor.id" class="row list-content">
										<div class="col-sm-10">{{ proveedor.nombre }}</div>
										<div class="col-sm-2 text-center">
											<img style="width: 15px" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Quitar proveedor" title="Quitar proveedor" @click="quitar_usuario(2,proveedor.id)">
										</div>
									</div>
								</div>
							</div>
							<div v-if="autorizador.proveedores.length > 1" class="col-sm-6">
								<div class="container-fluid">
									<div class="row list-title">
										<div class="col-sm-10">Proveedor</div>
										<div class="col-sm-2 text-center">#</div>
									</div>
									<div v-for="proveedor in bloque_b" :key="proveedor.id" class="row list-content">
										<div class="col-sm-10">{{ proveedor.nombre }}</div>
										<div class="col-sm-2 text-center">
											<img style="width: 15px" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Quitar proveedor" title="Quitar proveedor" @click="quitar_usuario(2,proveedor.id)">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else class="row">
							<div class="col-sm-12 text-center">No hay proveedores asignados</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-md-3">
							<button class="btn principal-btn" @click="guardar_autorizador">Guardar</button>
						</div>
						<div class="col-md-3">
							<button class="btn complementary-btn" @click="cancelar_autorizador">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="form_proveedor != null" :options="{width:'40vw'}" @close="cancelar_form_usuarios">
			<div class="title">{{ form_proveedor == 1 ? 'Pre-autorizador' : 'Proveedor' }}</div>
			<div class="body">
				<div v-if="form_proveedor == 1" class="row">
					<label for="list_usuarios" class="col-form-label col-sm-3">Pre-autorizador</label>
					<div class="col-sm-9">
						<select v-model="usuario_seleccionado" name="list_usuarios" id="list_usuarios" class="form-control">
							<option v-for="tmp_preautorizador in usuarios_disponibles" :key="tmp_preautorizador.id" :value="tmp_preautorizador.id">{{ tmp_preautorizador.nombre }}</option>
						</select>
					</div>
				</div>
				<div v-else class="row">
					<label for="list_usuarios" class="col-form-label col-sm-3">Pre-autorizador</label>
					<div class="col-sm-9">
						<select v-model="usuario_seleccionado" name="list_usuarios" id="list_usuarios" class="form-control">
							<option v-for="tmp_proveedor in proveedores_disponibles" :key="tmp_proveedor.id" :value="tmp_proveedor.id">{{ tmp_proveedor.nombre }}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-12 text-right">
						<button class="btn btn-primary mr-2" @click="guardar_usuario">Asignar {{ form_proveedor == 1 ? 'Pre-autorizador' : 'Proveedor'}}</button>
						<button class="btn btn-primary" @click="cancelar_form_usuarios">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="form_eliminar" :options="{type: 'error',width:'40vw'}" @close="cancelar_eliminar_autorizadores">
			<div class="title">Eliminar autorizador</div>
			<div class="body">
				<div class="row">
					<div class="col-sm-12 text-center">
						<p>Eliminarás <strong>{{ seleccionados.length }}</strong> proveedores</p>
						<p>¿Deseas continuar?</p>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-6">
						<button class="btn secondary-btn mr-2" @click="confirmar_eliminacion">Eliminar</button>
					</div>
					<div class="col-sm-3">
						<button class="btn complementary-btn" @click="cancelar_eliminar_autorizadores">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid'
	import Modal from '@/components/Modal'

	import api from '@/apps/tesoreria/api/tesoreria'
	import account from '@/api/account'

	import helper from '@/apps/tesoreria/Support/Helper'

	export default {
		components: {
			DataGrid, Modal
		}
		,data: () => ({
			dataGridConfig: {
				name: 'admin'
				,cols: {
					nombre: 'Nombre'
					,email: 'Email'
					,area: 'Área'
					,estatus: 'Estatus'
				}
				,filters: {
					cols: {
						id: 'ID'
						,nombre: 'Nombre'
						,email: 'Email'
						,area: 'Área'
						,estatus: {
							text: 'Estatus'
							,options: {
								1: 'Activo'
								,0: 'Inactivo'
							}
						}
						,created_at: 'Fecha creación'
					}
				}
				,paginator: {
					pagina_actual: 1
					,total_registros: 1
					,registros_por_pagina: 20
				}
				,mutators: {
					estatus: function(value) {
						return value == 1 ? 'Activo' : 'Inactivo';
					}
				}
			}
			,options: {
				page: 1
				,order_col: 'id'
				,order_dir: 'desc'
				,limit: 20
				,filters: []
			}
			,seleccionados: []
			,seleccionar: false
			,autorizadores: []
			,autorizador: {
				account_id: null
				,area: null
				,preautorizadores: []
				,proveedores: []
			}
			,autorizador_org: {
				account_id: null
				,area: null
			}
			,preautorizadores: []
			,preautorizadores_org: []
			,proveedores_org: []
			,proveedores: []
			,usuarios: []
			,usuario_seleccionado: null
			,areas: [
				'Fondeo y Finanzas'
				,'Operaciones'
				,'Comercial'
				,'Marketing'
				,'IT'
				,'Desarrollo de Negocio'
				,'Finanzas Corporativas y Relaciones con Inversionistas'
				,'Tesoreria'
				,'Dirección'
			]
			,form_autorizador: null
			,form_proveedor: null
			,form_eliminar: false
		})
		,created: function() {
			// this.obtener_autorizadores();
		}
		,methods: {
			data_grid_actions: function(type, data) {
				// this.$log.info(type, data);
				if (type == 'options') {
					this.options = data;
					this.obtener_autorizadores();
				}else {
					this.seleccionados = data;
					this.seleccionar = true;
				}
			}
			,obtener_autorizadores: async function() {
				try {
					let res = (await api.obtener_aprobadores(this.options)).data;

					this.autorizadores = res.data;
					this.dataGridConfig.paginator.pagina_actual = res.current_page;
					this.dataGridConfig.paginator.total_registros = res.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
				}catch(e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,obtener_preautorizadores: async function() {
				try {
					this.preautorizadores = (await api.obtener_preautorizadores()).data;
				}catch(e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,obtener_proveedores: async function() {
				try {
					this.proveedores = (await api.obtener_proveedores()).data;
				}catch(e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,obtener_usuarios: async function() {
				try {
					this.usuarios = (await api.obtener_usuarios()).data;
				}catch(e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,form_autorizador_tipo: async function(tipo) {
				await this.obtener_usuarios();
				await this.obtener_preautorizadores();
				await this.obtener_proveedores();

				if (tipo == 1)
					this.form_autorizador = tipo;
				else {
					if (this.seleccionados.length > 0) {
						this.form_autorizador = 2;
						this.autorizador = Object.assign({}, this.seleccionados[0]);
						this.preautorizadores_org = Array.from(this.seleccionados[0].preautorizadores);
						this.proveedores_org = Array.from(this.seleccionados[0].proveedores);
					}else {
						this.$helper.showMessage('Error','Tienes que seleccionar el autorizador a editar','error','alert');
					}
				}
			}
			,guardar_autorizador: async function() {
				if (this.form_autorizador == 1) {
					try {
						this.$log.info('autorizador', this.autorizador);

						let usuario = null;
						for(let i=0; i<this.usuarios.length; i++) {
							if (this.usuarios[i].id == this.autorizador.account_id) {
								usuario = this.usuarios[i];
								i=this.usuarios.length;
							}
						}

						let email = null;
						let email_sin_validar = null;

						for(let i=0; i<usuario.contactos.length; i++) {
							if (usuario.contactos[i].tipo == 'email') {
								if (usuario.contactos[i].validado == 1) {
									email = usuario.contactos[i].valor;
									i = usuario.contactos.length;
								}else {
									if (!email_sin_validar)
										email_sin_validar = usuario.contactos[i].valor;
								}
							}
						}

						let autorizador = (await api.crear_autorizador({
							account_id: this.autorizador.account_id
							,nombre: usuario.nombre
							,email: email ? email : email_sin_validar
							,area: this.autorizador.area
						})).data;

						this.$log.info('autorizador',autorizador);

						if (this.autorizador.preautorizadores.length > 0) {
							for(let i=0; i<this.autorizador.preautorizadores.length; i++) {
								let email_user = null;

								for(let e=0; e<this.autorizador.preautorizadores[i].contactos.length; e++) {
									if (this.autorizador.preautorizadores[i].contactos[e].tipo == 'email') {
										email_user = this.autorizador.preautorizadores[i].contactos[e].valor;
										e = this.autorizador.preautorizadores[i].contactos.length;
									}
								}

								if (!email_user) {
									if (!this.autorizador.preautorizadores[i].email)
										return this.$helper.showMessage('Error','El usuario indicado no tiene un correo definido','error','alert');

									email_user = this.autorizador.preautorizadores[i].email;
								}

								if (!email_user)
									return this.$helper.showMessage('Error','El usuario indicado no tiene un correo definido','error','alert');

								let preautorizador = {
									account_id: this.autorizador.preautorizadores[i].account_id
									,nombre: this.autorizador.preautorizadores[i].nombre
									,email: email_user
								}

								await api.asignar_preautorizador(autorizador.id, preautorizador);
							}
						}

						if (this.autorizador.proveedores.length > 0) {
							let proveedores_nuevos_id = [];
							for(let i=0; i<this.autorizador.proveedores.length; i++) {
								proveedores_nuevos_id.push(this.autorizador.proveedores[i].id);
							}

							await api.asignar_proveedor_autorizador(autorizador.id, {proveedores: proveedores_nuevos_id});
						}

						this.obtener_autorizadores();
						this.cancelar_autorizador();
					}catch(e) {
						this.$log.info('error', e);
						this.$helper.showAxiosError(e, 'Error');
					}
				}else {
					try {
						await api.editar_autorizador(this.autorizador.id, {area: this.autorizador.area});

						let preautorizadores_nuevos = helper.faltantes(this.autorizador.preautorizadores, this.preautorizadores_org);
						let preautorizadores_borrados = helper.faltantes(this.preautorizadores_org, this.autorizador.preautorizadores);

						let proveedores_nuevos =  helper.faltantes(this.autorizador.proveedores, this.proveedores_org);
						let proveedores_borrados = helper.faltantes(this.proveedores_org, this.autorizador.proveedores);

						if (preautorizadores_nuevos.length > 0)
							for(let i=0; i<preautorizadores_nuevos.length; i++) {
								let email_user = null;

								for(let e=0; e<preautorizadores_nuevos[i].contactos.length; e++) {
									if (preautorizadores_nuevos[i].contactos[e].tipo == 'email') {
										email_user = preautorizadores_nuevos[i].contactos[e].valor;
										e = preautorizadores_nuevos[i].contactos.length;
									}
								}

								if (!email_user) {
									if (!preautorizadores_nuevos[i].email)
										return this.$helper.showMessage('Error','El usuario indicado no tiene un correo definido','error','alert');

									email_user = preautorizadores_nuevos[i].email;
								}

								if (!email_user)
									return this.$helper.showMessage('Error','El usuario indicado no tiene un correo definido','error','alert');

								let preautorizador = {
									account_id: preautorizadores_nuevos[i].account_id
									,nombre: preautorizadores_nuevos[i].nombre
									,email: email_user
								}

								await api.asignar_preautorizador(this.autorizador.id, preautorizador);
							}

						if (preautorizadores_borrados.length > 0)
							for(let i=0; i<preautorizadores_borrados.length; i++) {
								await api.desasignar_preautorizador(this.autorizador.id, preautorizadores_borrados[i].id);
							}

						if (proveedores_nuevos.length > 0) {
							let proveedores_nuevos_id = [];
							for(let i=0; i<proveedores_nuevos.length; i++) {
								proveedores_nuevos_id.push(proveedores_nuevos[i].id);
							}

							await api.asignar_proveedor_autorizador(this.autorizador.id, {proveedores: proveedores_nuevos_id});
						}

						if (proveedores_borrados.length > 0) {
							let proveedores_borrar_id = [];
							for(let i=0; i<proveedores_borrados.length; i++) {
								proveedores_borrar_id.push(proveedores_borrados[i].id);
							}

							await api.desasignar_proveedor_autorizador(this.autorizador.id, {proveedores: proveedores_borrar_id});
						}

						this.obtener_autorizadores();
						this.cancelar_autorizador();
					}catch(e) {
						this.$log.info('error', e);
						this.$helper.showAxiosError(e, 'Error');
					}
				}
			}
			,cancelar_autorizador: function() {
				this.autorizador =  {
					account_id: null
					,area: null
					,preautorizadores: []
					,proveedores: []
				}
				this.preautorizadores_org = [];
				this.proveedores_org = [];
				this.form_autorizador = null;
				this.seleccionar = false;
			}
			,form_usuarios: function(tipo) {
				this.form_proveedor = tipo;
			}
			,cancelar_form_usuarios: function() {
				this.form_proveedor = null;
				this.usuario_seleccionado = null;
			}
			,guardar_usuario: function() {
				if (this.form_proveedor == 1) {
					for(let i=0; i<this.usuarios.length; i++) {
						if (this.usuarios[i].id == this.usuario_seleccionado) {
							let usuario = Object.assign({},this.usuarios[i])	;
							usuario['account_id'] = usuario.id;
							this.autorizador.preautorizadores.push(usuario);
							i=this.usuarios.length;
						}
					}
				}else {
					for(let i=0; i<this.proveedores.length; i++) {
						if (this.proveedores[i].id == this.usuario_seleccionado) {
							let usuario = Object.assign({},this.proveedores[i])	;
							this.autorizador.proveedores.push(usuario);
							i=this.proveedores.length;
						}
					}
				}

				this.cancelar_form_usuarios();
			}
			,quitar_usuario: function(tipo, id) {
				if (tipo == 1) {
					let usuarios = []
					for(let i=0; i<this.autorizador.preautorizadores.length; i++) {
						if (this.autorizador.preautorizadores[i].id != id)
							usuarios.push(this.autorizador.preautorizadores[i]);
					}

					this.autorizador.preautorizadores = usuarios;
				}else {
					let usuarios = []
					for(let i=0; i<this.autorizador.proveedores.length; i++) {
						if (this.autorizador.proveedores[i].id != id)
							usuarios.push(this.autorizador.proveedores[i]);
					}

					this.autorizador.proveedores = usuarios;
				}
			}
			,eliminar_autorizadores: function() {
				if (this.seleccionados.length > 0) {
					this.form_eliminar = true;
				}else {
					this.$helper.showMessage('Error','Debes seleccionar al menos un autorizador a eliminar','error','alert');
				}
			}
			,cancelar_eliminar_autorizadores: function() {
				this.form_eliminar = false;
				this.seleccionar = false;
			}
			,confirmar_eliminacion: async function() {
				try {
					for(let i=0; i<this.seleccionados.length; i++) {
						await api.eliminar_autorizador(this.seleccionados[i].id);
					}

					this.obtener_autorizadores();
					this.cancelar_eliminar_autorizadores();
				}catch(e) {
					this.$log.info('error', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
		}
		,computed: {
			bloque_a: function() {
				let bloque = [];

				for(let i=0; i<this.autorizador.proveedores.length; i++) {
					bloque.push(this.autorizador.proveedores[i]);
					// this.$log.info('i',i);
				}

				// this.$log.info('bloque_a', bloque);

				return bloque;
			}
			,bloque_b: function() {
				let bloque = [];

				for(let i=1; i<this.autorizador.proveedores.length; i++) {
					bloque.push(this.autorizador.proveedores[i]);
				}

				return bloque;
			}
			,preautorizador_bloque_a: function() {
				let bloque = [];

				for(let i=0; i<this.autorizador.preautorizadores.length; i++) {
					bloque.push(this.autorizador.preautorizadores[i]);
					// this.$log.info('i',i);
				}

				// this.$log.info('bloque_a', bloque);

				return bloque;
			}
			,preautorizador_bloque_b: function() {
				let bloque = [];

				for(let i=1; i<this.autorizador.preautorizadores.length; i++) {
					bloque.push(this.autorizador.preautorizadores[i]);
				}

				return bloque;
			}
			,usuarios_disponibles: function() {
				let disponibles = [];

				for(let i=0; i<this.usuarios.length; i++) {
					let encontrado = false;
					for(let a=0; a<this.preautorizadores.length; a++) {
						if (this.usuarios[i].id == this.preautorizadores[a].account_id) {
							encontrado = true;
							a=this.preautorizadores.length;
						}
					}

					if (!encontrado && this.autorizador)
						for(let b=0; b<this.autorizador.preautorizadores.length; b++) {
							if(this.usuarios[i].id == this.autorizador.preautorizadores[b].account_id) {
								encontrado = true;
								b=this.autorizador.preautorizadores.length;
							}
						}

					if (!encontrado)
						disponibles.push(this.usuarios[i]);
				}

				return disponibles;
			}
			,proveedores_disponibles: function() {
				let disponibles = [];

				for(let i=0; i<this.proveedores.length; i++) {
					let encontrado = false;

					for(let a=0; a<this.autorizador.proveedores.length; a++) {
						if (this.proveedores[i].account_id == this.autorizador.proveedores[a].account_id) {
							encontrado = true;
							a=this.autorizador.proveedores.length;
						}
					}

					if (!encontrado)
						disponibles.push(this.proveedores[i]);
				}

				return disponibles;
			}
		}
	}
</script>

<style lang="scss">
	.list-title {
		background-color: #000;
		color: #fff;
	}

	.list-content:nth-child(2n+1) {
		background-color: #e5e5e5;
	}
</style>